import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PostProcessingList from '../components/postprocess/PostProcessList';
import { VerticalBar } from '../components/styled/CommonStyled';

interface PostProcessPageProps {
    pageId?: string;
}

const PostProcessPage: React.FC<PostProcessPageProps> = ({ pageId }) => {
    return (<Container fluid>
        <Row>
            <VerticalBar sm={3} autoWidth={false}>
                <PostProcessingList />
            </VerticalBar>
            <Col sm={9}>

            </Col>
        </Row>
    </Container>);
}

export default PostProcessPage;