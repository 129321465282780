import React, { useState } from 'react';
import { Form, Container, Row, Col, FormGroup, FormLabel, FormCheck } from 'react-bootstrap';
import { StyledFormControl, StyledSelect } from '../../styled/CommonStyled';
import { GroupOption, transformGroupOptions } from '../../common/Functions';
import ReactSelectStyled from '../../styled/ReactSelectStyled';

const options = transformGroupOptions([{
    name: 'Bloomberg',
    id: 'Bloomberg'
}, {
    name: 'Refinitiv',
    id: 'Refinitiv'
}, {
    name: 'Vienna',
    id: 'Vienna'
}, {
    name: 'Listing',
    id: 'Listing'
}, {
    name: 'Specific PPM',
    id: 'Specific PPM'
}, {
    name: 'WKN',
    id: 'WKN'
}, {
    name: 'additional',
    id: 'additional'
}]);

const BasicProductInfo: React.FC = () => {
    const [selectedOptions, setSelectedOptions] = useState<GroupOption[]>([]);

    return (
        <Container>
            <Form>
                <Row>
                    <Col sm={12} md={12} xs={12}>
                        <FormGroup controlId="compartmentName">
                            <FormLabel>
                                Compartment Name
                                {/* Compartment Name (EMTNXX-45) - Fixed datafield, which will be used in the future everywhere the name should be shown/searched. A compartment can have different ISINs. EMTN (European Mid Term Note) is the product type, XX is the program year of MTCM (currently 24), 45 is in this case the 45th issue within the SPV. */}
                            </FormLabel>
                            <StyledFormControl
                                type="text"
                                maxLength={30}
                                placeholder="Compartment Name (EMTNXX-45)"
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} md={6} xs={6}>
                        <FormGroup controlId="compartmentName">
                            <FormLabel>
                                
                            </FormLabel>
                            <StyledSelect required>
                                <option value="single">Single</option>
                                <option value="basket">Basket</option>
                            </StyledSelect>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={6} xs={6}>
                        <FormGroup controlId="productType">
                            <FormLabel>Product Type</FormLabel>
                            <StyledSelect>
                                <option value="privateEquity">Private Equity</option>
                                <option value="privateDebt">Private Debt</option>
                                <option value="fund">Fund</option>
                                <option value="digitalAssets">Digital Assets</option>
                                <option value="collections">Collections</option>
                                <option value="otherRealAssets">Other Real Assets</option>
                            </StyledSelect>
                        </FormGroup>
                        <FormGroup controlId="kycDocumentation">
                            <FormCheck
                                type="checkbox"
                                label="KYC Documentation"
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm={2} md={2} xs={2}>
                        <FormGroup controlId="currency">
                            <FormLabel>
                                Currency
                                {/* Currency - Fixed datafield, which will be unchangeable for the product and should be in the database of the compartment. This will mostly only have visualization purposes. */}
                            </FormLabel>
                            <StyledSelect required>
                                <option value="chf">CHF</option>
                                <option value="usd">USD</option>
                                <option value="eur">EUR</option>
                                <option value="gbp">GBP</option>
                            </StyledSelect>
                        </FormGroup>
                    </Col>
                    <Col sm={5} md={5} xs={5}>
                        <FormGroup controlId="issueDate">
                            <FormLabel>Issue Date</FormLabel>
                            <StyledFormControl
                                type="date"
                                required />
                        </FormGroup>
                    </Col>
                    <Col sm={5} md={5} xs={5}>
                        <FormGroup controlId="maturityDate">
                            <FormLabel>Maturity Date</FormLabel>
                            <StyledFormControl
                                type="date"
                                required />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2} md={2} xs={2}>
                        <FormGroup controlId="interestRate">
                            <FormLabel>Interest Rate</FormLabel>
                            <StyledFormControl
                                type="number"
                                maxLength={4}
                                placeholder="XX.XX%"
                                required />
                        </FormGroup>
                    </Col>
                    <Col sm={5} md={5} xs={5}>
                        <FormGroup controlId="couponFrequency">
                            <FormLabel>
                                Coupon Frequency
                                {/* Currency - Fixed datafield, which will be unchangeable for the product and should be in the database of the compartment. This will mostly only have visualization purposes. */}
                            </FormLabel>
                            <StyledSelect required>
                                <option value="monthly">Monthly</option>
                                <option value="semiAnnual">Semi-Annual</option>
                                <option value="annually">Annually</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="weekly">Weekly</option>
                            </StyledSelect>
                        </FormGroup>
                    </Col>
                    <Col sm={5} md={5} xs={5}>
                        <FormGroup controlId="couponType">
                            <FormLabel>
                                Coupon Type
                                {/* Currency - Fixed datafield, which will be unchangeable for the product and should be in the database of the compartment. This will mostly only have visualization purposes. */}
                            </FormLabel>
                            <StyledSelect required>
                                <option value="fixed">Fixed</option>
                                <option value="floating">Floating</option>
                            </StyledSelect>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12} xs={12}>
                        <FormGroup controlId="compartmentName">
                            <FormLabel></FormLabel>
                            <ReactSelectStyled
                                isMulti={true}
                                placeholder=''
                                isSearchable={true}
                                value={selectedOptions}
                                onChange={(selected) => setSelectedOptions(selected as GroupOption[])}
                                options={options}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} md={6} xs={6}>
                        <FormGroup controlId="compartmentName">
                            <FormLabel>(International) ISIN</FormLabel>
                            <StyledFormControl
                                type="text"
                                maxLength={12}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={6} xs={6}>
                        <FormGroup controlId="compartmentName">
                            <FormLabel>VALOREN </FormLabel>
                            <StyledFormControl
                                type="text"
                                maxLength={9}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default BasicProductInfo;
