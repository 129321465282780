import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { ContentArea } from '../components/styled/CommonStyled';
import VerticleCollapsContainer from '../components/common/VerticleCollapsContainer';
import CaseManagementSideBar from '../components/casemanagement/CaseManagementSideBar';
import CaseManagementList from '../components/casemanagement/CaseManagementList';
import ProductSetup from '../components/casemanagement/ProductSetup';

interface CaseManagementPageProps {
    compartmentId?: string;
    pageId?: string;
}

const CaseManagementPage: React.FC<CaseManagementPageProps> = ({ compartmentId, pageId }) => {
    //const { userGroups } = useAuth();

    const renderComponentByPageId = () => {
        switch (pageId) {
            case 'compartment-overview':
                return (<></>);
            case 'subscriptions':
                return (<></>);
            default:
                return (<ProductSetup />);
        }
    }

    return (<Container fluid>
        <Row>
            <VerticleCollapsContainer sm={3} md={3} xs={3} titleText="Case Management">
                <CaseManagementList />
            </VerticleCollapsContainer>
            <VerticleCollapsContainer sm={3} md={3} xs={3} titleText="Compartment">
                <CaseManagementSideBar />
            </VerticleCollapsContainer>
            <ContentArea sm={6} className="flex-grow-1">
                {renderComponentByPageId()}
            </ContentArea>
        </Row>
    </Container >);
}

export default CaseManagementPage;