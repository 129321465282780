import styled from 'styled-components';

export const StyledAccordionHeader = styled.div`
  background-color:${({ theme }) => theme.hover};
  border-left: 0.25rem solid  ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.text};
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  font-weight: bolder;
`;

export const StyledAccordionBody = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 1.25rem;
  border: 1px solid ${({ theme }) => theme.border};
`;

export const StyledAccordionItem = styled.div`  
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: 1rem;
`;
