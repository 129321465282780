import React from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap';
import { BorderDiv, StyledButton, StyledFormControl, StyledListGroup, StyledListGroupItem } from '../styled/CommonStyled';
import { FaSearch } from 'react-icons/fa';


const items = [
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 2, title: 'Item 2', description: 'Description for Item 2' },
    { id: 3, title: 'Item 3', description: 'Description for Item 3' },
    { id: 4, title: 'Item 4', description: 'Description for Item 4' },
];



const PostProcessingList: React.FC = () => {
    return <div style={{ height: '90vh', maxHeight: '90vh' }}>
        <BorderDiv>
            <Col sm={12} md={12}>
                <InputGroup>
                    <StyledFormControl placeholder='Search'></StyledFormControl>
                    <StyledButton variant='secondary'>
                        <FaSearch size={20} />
                    </StyledButton>
                </InputGroup>
            </Col>
        </BorderDiv>
        <Col sm={12} md={12} style={{ height:'80vh', maxHeight:'90vh', overflow: 'auto' }}>
            <StyledListGroup>
                {items.map(item => (
                    <StyledListGroupItem key={item.id} >
                        <Row>
                            <Col sm={12}>
                                <b>{item.title}</b>
                                <p>{item.description}</p>
                            </Col>
                        </Row>
                    </StyledListGroupItem>
                ))}
            </StyledListGroup>
        </Col>
    </div>;
}

export default PostProcessingList;