import React from 'react';
import { Col,  Row } from 'react-bootstrap';
import { BorderDiv, StyledListGroup, StyledListGroupItem } from '../styled/CommonStyled';

const items = [
    { id: 1, title: 'Notification 1', description: 'Description for Notification 1' },
    { id: 2, title: 'Notification 2', description: 'Description for Notification 2' },
    { id: 3, title: 'Notification 3', description: 'Description for Notification 3' },
    { id: 4, title: 'Notification 4', description: 'Description for Notification 4' },
];


const NoticationsList: React.FC = () => {
    return <div style={{ height: '90vh', maxHeight: '90vh' }}>
        <BorderDiv>
            <Col sm={12} md={12}>
                <h3>Notifications</h3>
            </Col>
        </BorderDiv>
        <Col sm={12} md={12} style={{ height:'80vh', maxHeight:'90vh', overflow: 'auto' }}>
            <StyledListGroup>
                {items.map(item => (
                    <StyledListGroupItem key={item.id} >
                        <Row>
                            <Col sm={12}>
                                <b>{item.title}</b>
                                <p>{item.description}</p>
                            </Col>
                        </Row>
                    </StyledListGroupItem>
                ))}
            </StyledListGroup>
        </Col>
    </div>;
}

export default NoticationsList;