import React from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
//import { useAuth } from '../../context/AuthContext';
import { VerricleStyledNavLink } from '../styled/NavBarStyled';
import { FaFile, FaHandshakeSimple, FaRegWindowMaximize } from 'react-icons/fa6';
import { MdOutlinePlaylistAddCheck } from "react-icons/md";

const CaseManagementSideBar: React.FC = () => {
    const getItemsRoleBased = () => {
        const sideBarItems = [];

        //validate based on user group.
        // if (userGroups) {
        //     if (userGroups.find(x => x.name === "SuperAdmin")) {
        //     }
        // }

        sideBarItems.push((
            <Nav.Item className=" align-items-center">
                <LinkContainer key={'product-setup'} to="/case-management">
                    <VerricleStyledNavLink>
                        <FaRegWindowMaximize size={20} /> &nbsp; Product Setup
                    </VerricleStyledNavLink>
                </LinkContainer>
            </Nav.Item>
        ));

        sideBarItems.push((
            <Nav.Item className=" align-items-center">
                <LinkContainer key={'compartment-overview'} to="/case-management/compartment-overview">
                    <VerricleStyledNavLink>
                        <FaFile size={20} /> &nbsp; Compartment Overview
                    </VerricleStyledNavLink>
                </LinkContainer>
            </Nav.Item>
        ));

        sideBarItems.push((
            <Nav.Item className=" align-items-center">
                <LinkContainer key={'subscriptions'} to="/case-management/subscriptions">
                    <VerricleStyledNavLink>
                        <FaHandshakeSimple size={20} /> &nbsp; Subscriptions
                    </VerricleStyledNavLink>
                </LinkContainer>
            </Nav.Item>
        ));


        sideBarItems.push((
            <Nav.Item className=" align-items-center">
                <LinkContainer key={'issued'} to="/case-management/subscriptions">
                    <VerricleStyledNavLink>
                        <MdOutlinePlaylistAddCheck size={30} /> &nbsp; Issued
                    </VerricleStyledNavLink>
                </LinkContainer>
            </Nav.Item>
        ));

        return sideBarItems;
    }

    return (
        <Container>
            <Row>
                <Col sm={12} md={12}>
                    <Nav variant="pills" className="flex-column mt-3">
                        {getItemsRoleBased().map(x => x)}
                    </Nav>
                </Col>
            </Row>
        </Container>
    );
};

export default CaseManagementSideBar;
