import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import HomePage from './pages/HomePage';
import NotificationsPage from './pages/NotificationsPage';
import SettingsPage from './pages/SettingsPage';
import NavBar from './components/NavBar';
import VerticleNavBar from './components/VerticleNavBar';
import { Container, Row } from 'react-bootstrap';
import { ContentArea, VerticalBar } from './components/styled/CommonStyled';
import CaseManagementPage from './pages/CaseManagementPage';
import PostProcessPage from './pages/PostProcessPage';

function App() {
  return (
    <Container fluid>
      <div className="App">
        <header className="App-header">
          <Router>
            <Row>
              <NavBar />
            </Row>
            <Row className="d-flex">
              <VerticalBar xs={1} sm={1} md={1} className="d-flex" autoWidth={true}>
                <VerticleNavBar />
              </VerticalBar>
              <ContentArea xs={11} sm={11} md={11} className="flex-grow-1" style={{ paddingLeft: '0px' }}>
                <Routes>
                  <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<HomePage />}></Route>
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/notifications" element={<NotificationsPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route path="/settings/manage-user" element={<SettingsPage pageId='manage-user' />} />
                    <Route path="/settings/create-user" element={<SettingsPage pageId='create-user' />} />
                    <Route path="/case-management" element={<CaseManagementPage compartmentId='' />} />
                    <Route path="/case-management/compartment-overview" element={<CaseManagementPage compartmentId='' pageId='compartment-overview' />} />
                    <Route path="/case-management/subscriptions" element={<CaseManagementPage compartmentId='' pageId='subscriptions' />} />
                    <Route path="/nav-page" element={<PostProcessPage pageId='nav-page' />} />
                    <Route path="/interest-coupon" element={<PostProcessPage pageId='interest-coupon' />} />
                    <Route path="/buy-sell" element={<PostProcessPage pageId='buy-sell' />} />
                  </Route>
                </Routes>
              </ContentArea>
            </Row>
          </Router>
        </header>
      </div>
    </Container>
  );
}

export default App;
