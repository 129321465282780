import React, { useRef } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { ControlButton, KanbanContainer, ScrollContainer, ScrollItem, StyledCard, } from '../components/styled/CommonStyled';
import { useTheme } from '../context/ThemeContext';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const HomePage: React.FC = () => {
    const { theme } = useTheme();
    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    const cardContent = [
        { title: 'EMTN24-xx Alphaxxx', id: 'CH14533321496', amount: '6.5465', currency: 'CHF' },
        { title: 'EMTN24-xx Alphaxxx', id: 'CH14533321496', amount: '6.5465', currency: 'CHF' },
        { title: 'EMTN24-xx Alphaxxx', id: 'CH14533321496', amount: '6.5465', currency: 'CHF' },
        { title: 'EMTN24-xx Alphaxxx', id: 'CH14533321496', amount: '6.5465', currency: 'CHF' },
        { title: 'EMTN24-xx Alphaxxx', id: 'CH14533321496', amount: '6.5465', currency: 'CHF' },
        { title: 'EMTN24-xx Alphaxxx', id: 'CH14533321496', amount: '6.5465', currency: 'CHF' },
        { title: 'EMTN24-xx Alphaxxx', id: 'CH14533321496', amount: '6.5465', currency: 'CHF' },
        { title: 'EMTN24-xx Alphaxxx', id: 'CH14533321496', amount: '6.5465', currency: 'CHF' },
        { title: 'EMTN24-xx Alphaxxx', id: 'CH14533321496', amount: '6.5465', currency: 'CHF' },
    ];


    const items = [
        { id: 1, title: 'Item 1', description: 'Description for Item 1' },
        { id: 1, title: 'Item 1', description: 'Description for Item 1' },
        { id: 1, title: 'Item 1', description: 'Description for Item 1' },
        { id: 1, title: 'Item 1', description: 'Description for Item 1' },
        { id: 1, title: 'Item 1', description: 'Description for Item 1' },
        { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    ];

    return <Container fluid>
        <Row className="mt-3">
            <Col xs={12} sm={12} md={12}>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                    <Col sm={12} md={12} xs={12}>
                        <h5 style={{ color: theme.text }}>Last Used</h5>
                    </Col>
                    <ControlButton onClick={scrollLeft} style={{ left: '10px' }} className='left' variant='default' title="Left">
                        <FaAngleLeft size={20} />
                    </ControlButton>
                    <ScrollContainer ref={scrollRef}>
                        {cardContent.map((content, index) => (
                            <ScrollItem key={index}>
                                <StyledCard>
                                    <Card.Body>
                                        <Card.Title>{content.title}</Card.Title>
                                        <Card.Text>
                                            <p>{content.id}</p>
                                            <p style={{ fontWeight: 'bolder' }}>{content.currency} {content.amount}</p>
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </ScrollItem>
                        ))}
                    </ScrollContainer>
                    <ControlButton onClick={scrollRight} style={{ right: '10px' }} className='right' variant='default' title="Right">
                        <FaAngleRight size={20} />
                    </ControlButton>
                </div>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col xs={12} sm={12} md={12}>
                <Row>
                    <Col sm={12} md={12} xs={12}>
                        <h5 style={{ color: theme.text }}>Kanban Table</h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4} md={4} xs={4}>
                        <KanbanContainer>
                            <Card.Header style={{ background: theme.secondary, color: theme.text }}>Setup</Card.Header>
                            <Card.Body style={{ maxHeight: '42vh', overflowY: 'auto' }}>
                                {items.map(item => (
                                    <StyledCard key={item.id}>
                                        <Card.Body>
                                            <Card.Text>
                                                <b>{item.title}</b>
                                                <p style={{ marginBottom: '0rem' }}>{item.description}</p>
                                            </Card.Text>
                                        </Card.Body>
                                    </StyledCard>
                                ))}
                            </Card.Body>
                        </KanbanContainer>
                    </Col>
                    <Col sm={4} md={4} xs={4}>
                        <KanbanContainer>
                            <Card.Header style={{ background: theme.warning, color: theme.text }}>Subscription</Card.Header>
                            <Card.Body style={{ maxHeight: '42vh', overflowY: 'auto' }}>
                                {items.map(item => (
                                    <StyledCard key={item.id}>
                                        <Card.Body>
                                            <Card.Text>
                                                <b>{item.title}</b>
                                                <p style={{ marginBottom: '0rem' }}>{item.description}</p>
                                            </Card.Text>
                                        </Card.Body>
                                    </StyledCard>
                                ))}
                            </Card.Body>
                        </KanbanContainer>
                    </Col>
                    <Col sm={4} md={4} xs={4}>
                        <KanbanContainer>
                            <Card.Header style={{ background: theme.primary, color: theme.text }}>Issued</Card.Header>
                            <Card.Body style={{ maxHeight: '42vh', overflowY: 'auto' }}>
                                {items.map(item => (
                                    <StyledCard key={item.id}>
                                        <Card.Body>
                                            <Card.Text>
                                                <b>{item.title}</b>
                                                <p style={{ marginBottom: '0rem' }}>{item.description}</p>
                                            </Card.Text>
                                        </Card.Body>
                                    </StyledCard>
                                ))}
                            </Card.Body>
                        </KanbanContainer>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
};

export default HomePage;