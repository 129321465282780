import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { KanbanContainer } from '../styled/CommonStyled';
import { useTheme } from '../../context/ThemeContext';

const NoticationsDetail: React.FC = () => {
    const { theme } = useTheme();

    return <div>
        <Row>
            <Col sm={12} md={12}>
                <KanbanContainer className="mt-3">
                    <Card.Header style={{ background: theme.secondary, color: theme.text }}>Notication 1</Card.Header>
                    <Card.Body style={{ maxHeight: '80vh', overflowY: 'auto', color: theme.text }}>
                        <Card.Text>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et eros id quam vulputate finibus eu mattis erat. Nunc porta purus nibh. Praesent viverra dui tortor, a semper risus molestie vel. Aenean viverra mollis neque tempus pellentesque. Aliquam volutpat diam a ex scelerisque, ac ultrices mauris vulputate. In sit amet venenatis tellus. Integer laoreet vel tortor nec convallis. Fusce in cursus ipsum. Praesent in elit velit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis porttitor pellentesque imperdiet.

                            Cras cursus tempor lacus. In ligula nunc, venenatis ac elementum eget, sagittis nec orci. Aliquam scelerisque odio nisl, eu posuere enim pulvinar finibus. Morbi quis ante et massa blandit tincidunt nec quis nulla. Suspendisse potenti. Fusce mattis vestibulum pulvinar. Nullam sed convallis dui. Nullam eleifend lectus quis volutpat vestibulum. Phasellus facilisis vitae libero vel dictum. Donec luctus ligula nisl, non convallis leo pulvinar mattis. Duis scelerisque fermentum pellentesque. Nulla vel faucibus mauris, quis tempor arcu. Proin auctor quam et elit lobortis, nec aliquam augue malesuada. Nunc efficitur magna efficitur urna dignissim dignissim.

                            Proin non arcu quis massa vestibulum sagittis ut at odio. Sed eget elit bibendum, sagittis augue in, tincidunt leo. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In sed sapien ut justo accumsan dapibus. Donec ut dui bibendum, dignissim nulla aliquet, viverra nibh. Vivamus finibus neque libero, eu pellentesque mauris tempor eget. Praesent tempus lectus metus, at suscipit metus lobortis eget. Morbi imperdiet fermentum leo cursus malesuada. Quisque dignissim vel odio quis placerat. Aliquam congue vehicula nulla, lacinia fringilla urna interdum rhoncus. Fusce ut luctus turpis. Cras felis elit, sodales vel elit non, pretium pretium tortor. Morbi in eleifend nulla. Integer posuere nisl vel aliquet aliquam. Sed fermentum tortor sit amet leo malesuada, eget euismod sapien laoreet.

                            Nullam libero dolor, convallis ut nisi gravida, blandit rutrum massa. Proin malesuada varius condimentum. Nam auctor non ligula at varius. Nullam ac tellus eleifend felis pharetra volutpat eu vel purus. Nullam dignissim semper quam, quis egestas turpis dictum sed. Maecenas ac feugiat libero, sit amet gravida velit. Etiam velit dolor, posuere vel porttitor quis, commodo nec enim. Sed hendrerit tempus molestie. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed hendrerit semper augue pretium sagittis. Donec feugiat, est a vestibulum laoreet, leo velit malesuada est, ut ullamcorper est tortor ut nunc. Aenean sit amet venenatis eros. Sed quis finibus felis.

                            Fusce iaculis velit eget purus porttitor, ut rhoncus lorem pretium. Proin feugiat, dui vitae semper auctor, odio quam elementum orci, eget laoreet mauris elit in dolor. Maecenas tortor urna, blandit et condimentum eu, ornare at nunc. Quisque ac dapibus lacus, in tempor libero. Donec vel lorem vitae metus tristique fermentum vel quis mauris. Suspendisse efficitur metus ut hendrerit commodo. Cras egestas orci ut velit eleifend rhoncus. Morbi congue sollicitudin augue, sed imperdiet quam faucibus sed. Nullam euismod vulputate rutrum.
                        </Card.Text>
                    </Card.Body>
                </KanbanContainer>
            </Col>
        </Row>
    </div>;
}

export default NoticationsDetail;