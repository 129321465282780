import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BorderDiv, StyledButton, StyledListGroup, StyledListGroupItem } from '../styled/CommonStyled';
import ReactSelectStyled from '../styled/ReactSelectStyled';
import { FaPlus } from 'react-icons/fa6';
import { GroupOption, transformGroupOptions } from '../common/Functions';


const items = [
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 1, title: 'Item 1', description: 'Description for Item 1' },
    { id: 2, title: 'Item 2', description: 'Description for Item 2' },
    { id: 3, title: 'Item 3', description: 'Description for Item 3' },
    { id: 4, title: 'Item 4', description: 'Description for Item 4' },
];


const groupOptions = transformGroupOptions([{
    name: 'MTCM-XX001',
    id: 'XX001'
}, {
    name: 'MTCM-XX002',
    id: 'XX002'
}, {
    name: 'MTCM-XX003',
    id: 'XX003'
}]);

const CaseManagementList: React.FC = () => {
    const [selectedGroup, setSelectedGroup] = useState<GroupOption>(groupOptions[0]);

    return <div style={{ height: '90vh', maxHeight: '90vh' }}>
        <BorderDiv>
            <Row>
                <Col sm={9} md={9}>
                    <ReactSelectStyled
                        isMulti={false}
                        isSearchable={true}
                        value={[selectedGroup]}
                        onChange={(selected) => setSelectedGroup(selected as GroupOption)}
                        options={groupOptions}
                        placeholder="Search Compartment"
                    ></ReactSelectStyled>
                </Col>
                <Col sm={3} md={3}>
                    <StyledButton variant='primary'>
                        <FaPlus size={20} />
                    </StyledButton>
                </Col>
            </Row>
        </BorderDiv>
        <Col sm={12} md={12} style={{ height: '75vh', maxHeight: '82.5vh', overflowY: 'auto' }}>
            <StyledListGroup>
                {items.map(item => (
                    <StyledListGroupItem key={item.id} >
                        <Row>
                            <Col sm={12}>
                                <b>{item.title}</b>
                                <p>{item.description}</p>
                            </Col>
                        </Row>
                    </StyledListGroupItem>
                ))}
            </StyledListGroup>
        </Col>
    </div>;
}

export default CaseManagementList;