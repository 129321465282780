import React, { useState } from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { useTheme } from '../../context/ThemeContext';
import { StyledButton } from '../styled/CommonStyled';
import { FaAngleDown, FaAngleUp, FaCompressAlt, FaExpandAlt, FaInfoCircle } from 'react-icons/fa';
import { StyledAccordionBody, StyledAccordionHeader, StyledAccordionItem } from '../styled/AccordianStyled';
import BasicProductInfo from './productsetup/BasicProductInfo';

const ProductSetup: React.FC = () => {
    const { theme } = useTheme();
    const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>({
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
    });

    const toggleItem = (index: number) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const expandAll = () => {
        const allExpanded = Object.keys(expandedItems).every(
            key => expandedItems[Number(key)]
        );
        const newExpandedState = Object.keys(expandedItems).reduce(
            (acc, key) => ({
                ...acc,
                [Number(key)]: !allExpanded,
            }),
            {}
        );
        setExpandedItems(newExpandedState);
    };

    const renderEndArrow = (i: number) => {
        return <div className='float-end'>
            {expandedItems[i] ? <FaAngleUp /> : <FaAngleDown />}
        </div>;
    }

    return (
        <Container fluid>
            <Row className='mt-3 mb-3'>
                <Col sm={8} md={8} xs={8}>
                    <h3 style={{ color: theme.text }}>Product Setup</h3>
                </Col>
                <Col sm={4}>
                    <StyledButton variant='secondary' className='float-end verticle-center' onClick={expandAll}>
                        {(Object.values(expandedItems).every(isExpanded => isExpanded) ? <FaCompressAlt /> : <FaExpandAlt />)}
                        &nbsp;
                        {(Object.values(expandedItems).every(isExpanded => isExpanded) ? 'Close All' : 'Open All')}
                    </StyledButton>
                </Col>
            </Row>
            <Row style={{ overflowY: 'auto', height: '75vh', maxHeight: '80vh' }}>
                <Col sm={12} md={12} xs={12}>
                    <Accordion activeKey={Object.keys(expandedItems).filter(k => expandedItems[Number(k)])} flush>
                        <StyledAccordionItem as={Accordion.Item} eventKey="0">
                            <StyledAccordionHeader onClick={() => toggleItem(0)}>
                                Basic Product Information
                                &nbsp; <FaInfoCircle />
                                {renderEndArrow(0)}
                            </StyledAccordionHeader>
                            <StyledAccordionBody as={Accordion.Body}>
                                <BasicProductInfo />
                            </StyledAccordionBody>
                        </StyledAccordionItem>

                        <StyledAccordionItem as={Accordion.Item} eventKey="1">
                            <StyledAccordionHeader onClick={() => toggleItem(1)}>
                                Financial and Structural Details
                                &nbsp; <FaInfoCircle />
                                {renderEndArrow(1)}
                            </StyledAccordionHeader>
                            <StyledAccordionBody as={Accordion.Body}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
                            </StyledAccordionBody>
                        </StyledAccordionItem>

                        <StyledAccordionItem as={Accordion.Item} eventKey="2">
                            <StyledAccordionHeader onClick={() => toggleItem(2)}>
                                Key Dates and Schedules
                                &nbsp; <FaInfoCircle />
                                {renderEndArrow(2)}
                            </StyledAccordionHeader>
                            <StyledAccordionBody as={Accordion.Body}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
                            </StyledAccordionBody>
                        </StyledAccordionItem>

                        <StyledAccordionItem as={Accordion.Item} eventKey="3">
                            <StyledAccordionHeader onClick={() => toggleItem(3)}>
                                Parties Involved
                                &nbsp; <FaInfoCircle />
                                {renderEndArrow(3)}
                            </StyledAccordionHeader>
                            <StyledAccordionBody as={Accordion.Body}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
                            </StyledAccordionBody>
                        </StyledAccordionItem>

                        <StyledAccordionItem as={Accordion.Item} eventKey="4">
                            <StyledAccordionHeader onClick={() => toggleItem(4)}>
                                Document Archive
                                &nbsp; <FaInfoCircle />
                                {renderEndArrow(4)}
                            </StyledAccordionHeader>
                            <StyledAccordionBody as={Accordion.Body}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
                            </StyledAccordionBody>
                        </StyledAccordionItem>
                    </Accordion>
                </Col>
            </Row>
        </Container >
    );
}

export default ProductSetup;