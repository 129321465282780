import { Button, Card, Col, Table } from "react-bootstrap";
import { css, styled } from "styled-components";
import { Form } from 'react-bootstrap';

export const StyledTable = styled(Table)`
  border-color: ${({ theme }) => theme.border} !important;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.background};

  th, td {
    border-color: ${({ theme }) => theme.border} !important;
  }

  th {
    background-color: ${({ theme }) => theme.hover};
    color: ${({ theme }) => theme.text};
  }

  tr:hover {
    background-color: ${({ theme }) => theme.hover} !important;
  }
`;

interface StyledButtonProps {
  variant: 'primary' | 'secondary' | 'success' | 'warning';
}

const buttonStyles = (variant: 'primary' | 'secondary' | 'success' | 'warning', theme: any) => {
  switch (variant) {
    case 'primary':
      return css`
        background-color: ${theme.primary};
        color: ${theme.text};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
    case 'secondary':
      return css`
        background-color: ${theme.secondary};
        color: ${theme.text};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
    case 'success':
      return css`
        background-color: ${theme.success};
        color: ${theme.text};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
    case 'warning':
      return css`
        background-color: ${theme.warning};
        color: ${theme.text === '#ffffff' ? '#000000' : '#ffffff'};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
    default:
      return css`
        background-color: ${theme.primary};
        color: ${theme.text};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
  }
};

export const StyledButton = styled.button<StyledButtonProps>`
  padding: ${({ theme }) => theme.padding};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  font-size: 16px;
  cursor: pointer;

  ${({ variant, theme }) => buttonStyles(variant, theme)}
`;


export const StyledFormControl = styled(Form.Control)`
  background-color: ${({ theme }) => theme.background} !important;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border};
  border-bottom: 2px solid ${({ theme }) => theme.border};
  outline: none;

  &::placeholder{
    color: ${({ theme }) => theme.text};
  }

  &:focus {
    background-color: ${({ theme }) => theme.hover};
    border-bottom: 2px solid ${({ theme }) => theme.warning};
    color: ${({ theme }) => theme.text};
  }
`;

export const StyledSelect = styled(Form.Select)`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border};
  border-bottom: 2px solid ${({ theme }) => theme.border};
  outline: none;
  
  &:focus {
    background-color: ${({ theme }) => theme.hover};
    color: ${({ theme }) => theme.text};
    border-bottom: 2px solid ${({ theme }) => theme.warning};
  }
`;

export const BorderDiv = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  max-height: 10vh;
`;

interface VerticalBarProps { autoWidth: boolean; }
export const VerticalBar = styled(Col) <VerticalBarProps>`
  background: ${({ theme }) => theme.background};
  color:  ${({ theme }) => theme.text} !important;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  padding-right: 0px;
  height: 100vh;
  ${(props) => props.autoWidth && `width: fit-content`};
  border-right: solid 1px ${({ theme }) => theme.border};
`;

export const ContentArea = styled(Col)`
  background-color: ${({ theme }) => theme.background};
  height: 100vh;
`;

export const StyledListGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    padding: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.border};
    border-radius: 4px;    
    padding: 4px;
  }
`;

export const StyledListGroupItem = styled.div`
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  border-bottom: 1px solid ${({ theme }) => theme.border};
  border-left: 0.25rem solid transparent;
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s, transform 0.3s;

  &:last-child {
    border-bottom: none;
  }

  &.active, 
  &:hover {
    background-color:${({ theme }) => theme.hover};
    border-left: 0.25rem solid  ${({ theme }) => theme.border};
  }
`;

export const VerticalText = styled.div`
  color: ${({ theme }) => theme.text};
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  position: absolute;
  bottom: 5.5rem;
  left: 5%;
  transform: translate(-50%, 1);
  white-space: nowrap;
`;

export const TopButton = styled.div`
  color: ${({ theme }) => theme.text};
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const ScrollContainer = styled.div`
  display: flex;
  overflow-x: hidden;
  padding: 1rem;
  scroll-snap-type: x mandatory;
  width: 90%;
  max-width: 90%;
  margin-left: 5%;
`;

export const ScrollItem = styled.div`
  flex: 0 0 auto;
  margin-right: 1rem;
  scroll-snap-align: start;
`;

export const ControlButton = styled(Button)`
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  z-index: 2;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  height: 60%;

  &.left{
    border-left: solid 0.25rem transparent;
  }

  &.right{
    border-right: solid 0.25rem transparent;
  }

  &.left:hover {
    border-left: solid 0.25rem  ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.hover};
    color: ${({ theme }) => theme.text};
  }
  
  &.right:hover {
    border-right: solid 0.25rem  ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.hover};
    color: ${({ theme }) => theme.text};
  }
`;

export const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.hover} !important;
  border-left: solid 0.25rem transparent;
  color: ${({ theme }) => theme.text};
  margin: 0.25rem;

  &: hover {
    border-left: solid 0.25rem ${({ theme }) => theme.border};
    cursor: pointer;
  }
`;

export const KanbanContainer = styled(Card)`
  background: transparent !important;
  border: solid 1px ${({ theme }) => theme.border};
  border-radius: 0px !important;

  .card-header{
    border-radius: 0px !important;
    border-bottom: solid 1px ${({ theme }) => theme.border};
  }
`;