import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { VerticalBar } from '../components/styled/CommonStyled';
import NoticationsList from '../components/notications/NoticationsList';
import NoticationsDetail from '../components/notications/NoticationsDetailedView';

const NotificationsPage: React.FC = () => {

    return (<Container fluid>
        <Row>
            <VerticalBar sm={3} autoWidth={false}>
                <NoticationsList />
            </VerticalBar>
            <Col sm={9}>
                <NoticationsDetail />
            </Col>
        </Row>
    </Container>);
}

export default NotificationsPage;