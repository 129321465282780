import React from 'react';
import { Container, Row } from 'react-bootstrap';
import SettingsSidebar from '../components/settings/SettingsSideBar.';
import ListUsers from '../components/settings/ListUsers';
import UserProfile from '../components/settings/UserProfile';
import UserForm from '../components/settings/UserForm';
import { ContentArea, VerticalBar } from '../components/styled/CommonStyled';

interface SettingsPageProps {
    pageId?: string;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ pageId }) => {

    const renderComponentByPageId = () => {
        switch (pageId) {
            case 'manage-user':
                return <ListUsers />;
            case 'create-user':
                return <UserForm />;
            default:
                return <UserProfile />;
        }
    }

    return (
        <Container fluid>
            <Row>
                <VerticalBar sm={3} autoWidth={false}>
                    <SettingsSidebar />
                </VerticalBar>
                <ContentArea sm={9}>
                    {renderComponentByPageId()}
                </ContentArea>
            </Row>
        </Container>
    );
};

export default SettingsPage;