import React from 'react';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { useTheme } from '../../context/ThemeContext';

interface GroupOption {
    value: string;
    label: string;
}

interface ReactSelectProps {
    isMulti: boolean;
    placeholder: string, 
    isSearchable: boolean;
    options: GroupOption[];
    value: GroupOption[];
    onChange: (newValue: OnChangeValue<GroupOption, boolean>, actionMeta: ActionMeta<GroupOption>) => void;
}

const ReactSelectStyled: React.FC<ReactSelectProps> = ({ isMulti, isSearchable, placeholder, options, value, onChange }) => {
    const { theme } = useTheme();
    const customSelectStyles = {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: theme.background,
            borderColor: theme.border,
            borderBottom: `2px solid ${theme.border}`,
            color: theme.text,
            '&:focus-within': {
                borderBottom: `2px solid ${theme.warning}`,
            },
            '&:hover': {
                borderColor: theme.border,
            },
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: theme.background,
            '&:hover': {
                backgroundColor: theme.hover,
                color: theme.text,
            },
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: theme.background,
            border: `1px solid ${theme.border}`,
            color: theme.text,
        }),
        multiValue: (provided: any) => ({
            ...provided,
            background: theme.selectValueBackground,
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            color: theme.text,
        }),
        multiValueRemove: (provided: any) => ({
            ...provided,
            color: theme.text,
            '&:hover': {
                backgroundColor: theme.hover,
                color: theme.text,
            },
        }),
        singleValue:(provided: any, state: any) => ({
            ...provided,
            color: theme.text,
        }),
        input:(provided: any, state: any) => ({
            ...provided,
            color: theme.text,
        }),
        placeholder:(provided: any, state: any) => ({
            ...provided,
            color: theme.text,
        }),
    };

    return (
        <Select
            isMulti={isMulti}
            isSearchable={isSearchable}
            value={value}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            styles={customSelectStyles}
        />
    );
};

export default ReactSelectStyled;
